import EntityRepository from '@/core/repository/entityRepository'
import FileRepository from '@/core/repository/company/fileRepository'

import { typeOf } from '@/helpers/type'
import User from '@/model/user'

export default new class BusinessUserRepository extends EntityRepository {
  baseUri() {
    return 'company/business-users'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, User)) {
      ent = new User()
    }

    ent.uuid = props.uuid || null
    ent.role = props.role || null
    ent.email = props.email || null
    ent.username = props.username || null
    ent.firstName = props.firstName || null
    ent.lastName = props.lastName || null
    ent.phone = props.phone || null
    ent.phoneVerified = props.phoneVerified || null
    ent.enabled = props.enabled || null
    ent.locale = props.locale || null
    ent.emailVerified = props.emailVerified || null
    ent.avatar = FileRepository.mapEntity(props.avatar || null)

    return ent
  }
}()
