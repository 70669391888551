import Entity from './entity'

export default class User extends Entity {
  /** @type String */
  uuid;
  /** @type String */
  role;
  /** @type String */
  email;
  /** @type String */
  username;
  /** @type String */
  firstName;
  /** @type String */
  lastName;
  /** @type String */
  phone;
  /** @type String */
  phoneVerified
  /** @type String */
  avatar;
  /** @type String */
  enabled;
  /** @type String */
  locale;
  /** @type String */
  emailVerified;
}
