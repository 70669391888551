<template>

<div
  :style="{ 'background-image': `url(${previewImage})` }"
  id="kt_image_5"
  class="image-input image-input-empty image-input-outline"
>
  <div class="image-input-wrapper" />

  <label
    data-action="change"
    data-toggle="tooltip"
    title=""
    data-original-title="Change avatar"
    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
  >
    <i class="fa fa-pen icon-sm text-muted" />
    <input
      ref="fileInput"
      v-bind="$attrs"
      @change="pickFile"
      type="file"
    >
    <input type="hidden" name="profile_avatar_remove">
  </label>

  <span
    :class="{'d-block': !!previewImage}"
    @click="removeImage"
    data-action="remove"
    data-toggle="tooltip"
    title="Remove avatar"
    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
  >
    <i class="ki ki-bold-close icon-xs text-muted" />
  </span>
</div>
</template>

<script>
export default {
  name: 'FileImage',
  props: {
    value: [Object, File],
  },
  data() {
    return {
      previewImage: null,
    }
  },
  watch: {
    value: {
      handler(item) {
        if (item?.uuid) this.previewImage = `${process.env.VUE_APP_BACKEND_ORIGIN}/${item.href}`
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click()
    },
    removeImage() {
      this.$emit('input', null)
      this.previewImage = null
      this.$refs.fileInput.value = null
    },
    pickFile() {
      const input = this.$refs.fileInput
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
  },
}
</script>
