<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('EMPLOYEES.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'businessUser' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="input-group-3" :label="`${$t('FORM.AVATAR')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
              <FileImage
                id="input-3"
                v-model="$v.form.avatar.$model"
                accept="image/*"
                aria-describedby="input-3-feedback"
                @input="clearServerError('avatar')"
              />
              <b-form-invalid-feedback id="input-3-feedback" :class="{'d-block': validateState('avatar') === false}">
                <template v-if="serverErrors.avatar">{{ serverErrors.avatar[0] }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-1" :label="`${$t('FORM.FIRST_NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                :placeholder="$t('PLACEHOLDER.FIRST_NAME')"
                trim
                @input="clearServerError('firstName')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.firstName">{{ serverErrors.firstName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.FIRST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-6" :label="`${$t('FORM.LAST_NAME')}`" label-for="input-6" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-6"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                :placeholder="$t('PLACEHOLDER.LAST_NAME')"
                trim
                @input="clearServerError('lastName')"
                aria-describedby="input-6-feedback"
              />
              <b-form-invalid-feedback id="input-6-feedback">
                <template v-if="serverErrors.lastName">{{ serverErrors.lastName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LAST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('FORM.PASSWORD')}`" label-for="input-7" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-7"
                v-model="$v.form.plainPassword.$model"
                :state="validateState('plainPassword')"
                :placeholder="$t('PLACEHOLDER.PASSWORD')"
                trim
                @input="clearServerError('plainPassword')"
                aria-describedby="input-7-feedback"
              />
              <b-form-invalid-feedback id="input-7-feedback">
                <template v-if="serverErrors.plainPassword">{{ serverErrors.plainPassword[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PASSWORD') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-4" :label="`${$t('FORM.PHONE')}`" label-for="input-4" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-4"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                :placeholder="$t('PLACEHOLDER.PHONE')"
                @input="clearServerError('phone')"
                aria-describedby="input-4-feedback"
              />
              <b-form-invalid-feedback id="input-4-feedback">
                <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
                <template v-else-if="!$v.form.phone.alphaPhone">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-5" :label="`${$t('FORM.EMAIL')}`" label-for="input-5" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-5"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                :placeholder="$t('PLACEHOLDER.EMAIL')"
                @input="clearServerError('email')"
                aria-describedby="input-5-feedback"
              />
              <b-form-invalid-feedback id="input-5-feedback">
                <template v-if="serverErrors.email">{{ serverErrors.email[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.EMAIL') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-2" :label="`${$t('FORM.ROLE')}`" label-for="select-1" label-cols-md="2" label-align-md="right">
              <SelectInfinityScroll
                id="select-1"
                v-model="$v.form.role.$model"
                :search.sync="searchRole"
                :state="validateState('role') === false"
                :items="optionsRole"
                clearable
                @change="$v.form.groups.$reset()"
                @input="clearServerError('role')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('role') === false}">
                <template v-if="serverErrors.role">{{ serverErrors.role[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.ROLE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              v-if="form.role && form.role.uuid === 'ROLE_BUSINESS_ADMIN'"
              :label="`${$t('FORM.GROUPS')}`"
              label-for="select-2"
              label-cols-md="2"
              label-align-md="right"
            >
              <SelectInfinityScroll
                id="select-2"
                multiple
                v-model="$v.form.groups.$model"
                :search.sync="searchGroups"
                :state="validateState('groups') === false"
                clearable
                api="company/user-groups"
                @input="clearServerError('groups')"
                aria-describedby="select-1-feedback"
              />
              <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('groups') === false}">
                <template v-if="serverErrors.groups">{{ serverErrors.groups[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.GROUPS') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-7" :label="`${$t('FORM.ENABLED')}`" label-for="check-1" label-cols-md="2" label-align-md="right">
              <span class="switch switch-icon">
                <label>
                  <input
                    id="check-1"
                    type="checkbox"
                    v-model="form.enabled"
                    value="true"
                  >
                  <span />
                </label>
              </span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, helpers, requiredIf } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/businessUserRepository'
import RepoFile from '@/core/repository/company/fileRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../content/Card'
import InputForm from '../../../../components/forms-items/input'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import { TEL } from '../../../../helpers/validation'
import FileImage from '../../../../components/forms-items/FileImage'
import SelectInfinityScroll from '../../../../components/forms-items/SelectInfinityScroll'

export default {
  name: 'CreateBusinessUser',
  components: {
    SelectInfinityScroll,
    FileImage,
    InputForm,
    KTCard, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      file: null,
      optionsRole: [
        {
          uuid: 'ROLE_BUSINESS_OWNER',
          name: this.$t('EMPLOYEES.ROLES.ROLE_BUSINESS_OWNER'), 
        },
        {
          uuid: 'ROLE_BUSINESS_ADMIN',
          name: this.$t('EMPLOYEES.ROLES.ROLE_BUSINESS_ADMIN'), 
        },
      ],
      searchRole: '',
      searchGroups: '',
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        plainPassword: '',
        groups: null,
        role: null,
        avatar: null,
        enabled: true,
      },
      validations: {
        form: {
          firstName: { required },
          plainPassword: { required },
          lastName: { required },
          email: { required },
          avatar: {},
          role: { required },
          groups: {
            required: requiredIf(() => {
              return this.form?.role?.uuid === 'ROLE_BUSINESS_ADMIN'
            }),
          },
          phone: {
            required,
            alphaPhone: helpers.regex('alpha', TEL), 
          },
        },
      },
    }
  },
  mounted() {
    this.generatePasswaord()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('EMPLOYEES.TITLE_NEW') }])
  },
  methods: {
    generatePasswaord() {
      const charactersArray = 'a-z,A-Z,0-9,#'.split(',')
      let CharacterSet = ''
      let password = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < 16; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      this.form.plainPassword = password
    },
    resetForm() {
      this.form = {
        firstName: '',
        lastName: '',
        plainPassword: '',
        email: '',
        phone: '',
        role: '',
        avatar: null,
        groups: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    addFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return RepoFile.post(formData)
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      if (this.form.avatar) {
        const { data } = await this.addFile(this.form.avatar)
        obj.avatar = data.payload.uuid
      }
      obj.role = obj.role.uuid
      obj.groups = obj.groups ? obj.groups.map((m) => m.uuid) : obj.groups
      Repo.post(obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.CREATED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        this.resetForm()
        setTimeout(() => this.$router.push({ name: 'businessUser' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
